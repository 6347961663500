<script lang="ts">
import type { Events, ModalDataHtmlContent } from '@components/Modals/types'

export const events: Events = null
export let data: ModalDataHtmlContent

const { content } = data
</script>

<div class="modal-body">
    {@html content}
</div>
