<!-- svelte-ignore a11y-media-has-caption -->
<script lang="ts">
import type { Events, ModalDataVideoContent } from '@components/Modals/types'

export const events: Events = null
export let data: ModalDataVideoContent

const { source, aspectRatio = '16-9', attributes = {} } = data
</script>

<div class="modal-body">
    <video class={`video-js vjs-default-skin vjs-${aspectRatio}`} controls controlslist="nodownload" preload="auto" {...attributes}>
        <source src={source} type="video/mp4" />
        <p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>
    </video>
</div>
