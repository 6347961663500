<script lang="ts">
import { createEventDispatcher, onMount } from 'svelte'
import { fly } from 'svelte/transition'
import type { Events, ModalDataTexteditor } from '@components/Modals/types'

export let events: Events
export let data: ModalDataTexteditor

let currentContent = ''
let savedContent = ''

let isLocked = false
let isLoading: boolean
let isSaving: boolean

let showSaveFeedback: boolean = false
let saveFeedbackTimeout: number = null

let messages: {
  type: string
  text: string
}[] = []

const dispatch = createEventDispatcher()

const themeUtils = window.M.theme_soon.util

const {
  id: modalId,
  intro,
  minChars = null,
  maxChars = null,
  confirmMessage = null,
  saveMessage = null,
  onAfterSave = null,
} = data

type StringTuple = [ string, string?, string? ]

const defaultButtonLabels: {
    [key: string]: StringTuple
} = {
  save:   [ 'save',   'theme_soon' ],
  saving: [ 'saving', 'theme_soon' ],
  close:  [ 'close',  'theme_soon' ],
}

let buttonLabels = {
  save:   data.buttonLabels?.save   || themeUtils.get_string(...defaultButtonLabels.save),
  saving: data.buttonLabels?.saving || themeUtils.get_string(...defaultButtonLabels.saving),
  close:  data.buttonLabels?.close  || themeUtils.get_string(...defaultButtonLabels.close),
}

let minCharsSatisfied = Number.isFinite(minChars) ? false : true
$: if (Number.isFinite(minChars)) {
  minCharsSatisfied = currentContent.length > minChars
}

let maxCharsSatisfied = Number.isFinite(maxChars) ? false : true
$: if (Number.isFinite(maxChars)) {
  maxCharsSatisfied = currentContent.length <= maxChars
}

const loadAction = !data.loadAction ? null : (
  typeof data.loadAction === 'function' ? data.loadAction : createLoadAction(data.loadAction)
)

const saveAction = !data.saveAction ? null : (
  typeof data.saveAction === 'function' ? data.saveAction : createSaveAction(data.saveAction)
)

$: if (!isLocked && savedContent != currentContent) {
  events.emit('MODAL_LOCK', { id: modalId, text: confirmMessage})
  isLocked = true
}

$: if (isLocked && savedContent == currentContent) {
  events.emit('MODAL_UNLOCK', { id: modalId })
  isLocked = false
}

if (loadAction) {
  loadContent()
}

function createLoadAction(url: string) {
  return async () => {
    const response = await fetch(url, {
      method: 'GET'
    })

    if (response.ok) {
      const json = await response.json()
      return json
    }
  }
}

function createSaveAction(url: string) {
  return async (content) => {
    const formData = new FormData()
    formData.append('content', content)

    const response = await fetch(url, {
      method: 'POST',
      body: formData
    })

    if (response.ok) {
      const json = await response.json()
      return json
    }
  }
}

async function loadContent() {
  isLoading = true

  const json = await loadAction()

  savedContent = json.content
  currentContent = json.content

  isLoading = false
}

async function saveContent() {
  isSaving = true

  saveFeedbackTimeout = setTimeout(() => {
    showSaveFeedback = true
  }, 100)

  const json = await saveAction(currentContent)

  if (json?.hasOwnProperty('content')) {
    currentContent = json.content
  }

  savedContent = currentContent
  isSaving = false
  showSaveFeedback = false
  clearTimeout(saveFeedbackTimeout)

  if (saveMessage) {
    const saveMessageItem = {
      type: 'success',
      text: saveMessage
    }

    messages.push(saveMessageItem)

    setTimeout(() => {
      messages = messages.filter((item) => item !== saveMessageItem)
    }, 4000)
  }

  if (onAfterSave) {
    onAfterSave({
      content: currentContent
    })
  }
}

function handleCloseClick() {
  dispatch('closemodal')
}

function handleSaveClick() {
  if (!isSaving) {
    saveContent()
  }
}

onMount(async () => {
  let stringsToLoad: {
    save?:   StringTuple
    saving?: StringTuple
    close?:  StringTuple
  } = {}

  for (const key in buttonLabels) {
    if (!data.buttonLabels?.[key]) {
      stringsToLoad[key] = defaultButtonLabels[key]
    }
  }

  await themeUtils.load_strings(Object.keys(stringsToLoad).map(item => (stringsToLoad[item])))

  buttonLabels = {
    ...buttonLabels,
    ...Object.keys(stringsToLoad).reduce((result, key) => {
      result[key] = themeUtils.get_string(...stringsToLoad[key as keyof typeof stringsToLoad])
      return result
    }, {})
  }
})
</script>

<div class="modal-body d-flex flex-column">
  {#if intro}
    <div class="modal--texteditor__intro">
      {@html intro}
    </div>
  {/if}

  <textarea disabled={showSaveFeedback || isLoading} bind:value={currentContent} class="form-control" id="" name="" cols="30" rows="10"></textarea>

  <div class="modal--texteditor__bottom">
    {#if maxChars}
      <div class="modal--texteditor__content-maxchars small mt-2">
        {#if maxChars}
          Anzahl Zeichen: <span class:text-danger={currentContent.length > maxChars}>{currentContent.length}</span> / {maxChars}
        {/if}
      </div>
    {/if}

    {#if currentContent !== savedContent}
      <div transition:fly class="modal--texteditor__content-changed text-muted small mt-2">
        Ungespeicherte Änderungen.
      </div>
    {/if}
  </div>

  {#if messages?.length}
    <div class="modal--texteditor__messages mt-3">
      {#each messages as message}
        <div transition:fly class={`alert alert-${message.type}`}>{message.text}</div>
      {/each}
    </div>
  {/if}

  <div class="d-flex flex-row justify-content-between mt-3">
    {#if saveAction}
      <button type="button" disabled={showSaveFeedback || !minCharsSatisfied || !maxCharsSatisfied || currentContent == savedContent} class="btn btn-primary" on:click={handleSaveClick}>
        {#if showSaveFeedback}
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {buttonLabels?.saving}...
        {:else}
          {buttonLabels?.save}
        {/if}
      </button>
    {/if}

    <button type="button" class="btn btn-outline-primary" on:click={handleCloseClick}>
      {buttonLabels?.close}
    </button>
  </div>
</div>
