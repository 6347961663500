<script lang="ts">
import type { ModalDataIframeContent } from '@components/Modals/types'

export let data: ModalDataIframeContent

const { url, height = 800 } = data
</script>

<div class="modal-body">
  <iframe title="Modal content" src={url} height={height} frameborder="0"></iframe>
</div>
